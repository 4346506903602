<template>
  <Modal coach-tv-filter-modal @close="onClose">
    <CoachTvFilterModalView :platformFilterList="platformFilterList" :playlistFilterList="playlistFilterList" @save="onSave"/>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import CoachTvFilterModalView from '@/views/components/coaching/coachTv/CoachTvFilterModalView.vue';

export default {
  name: 'CoachTvFilterModal',
  components: {
    Modal, CoachTvFilterModalView
  },
  data: () => ({
    playlistFilterList: [],
    platformFilterList: [],
  }),
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSave(filterResult) {
      return this.$emit('resolve', filterResult);
    }
  },
  async mounted() {
    const result = await this.$services.coaching.getCoachTvFilter();
    this.platformFilterList = result?.platforms || [];
    this.playlistFilterList = result?.playlist || [];
  }
};
</script>

<style lang="less">
[coach-tv-filter-modal] {}
</style>
