<template>
  <div coach-tv-view-filter>
    <div class="top-wrap">
      <button class="btn-prev" @click="prevBtnHandler">
        <img src="/img/icon/icon-arrow-down-dark.svg">
      </button>
    </div>
    <div class="content-wrap">
      <div class="content-holder">
        <div class="search-field">
          <TextInput v-model="searchText" :placeholder="$t('coaching.MC_PLACEHOLDER_SEARCH_TV')" class="search-text" @keydown.native.enter="setTitleFilter" />
          <button class="search-btn" @click="setTitleFilter">
            <img class="search-icon" src="/img/icon/icon-search-gray.svg" />
          </button>
        </div>
        <div class="filter-field">
          <div class="filter-btn playlist" @click="showFilterModal">
            <span class="btn-name-text">{{ $t('coaching.PC_FILTER_PLAYLIST') }} :</span>
            <span class="btn-text">{{ playlistFilterText }}</span>
          </div>
          <div class="filter-btn platform" @click="showFilterModal">
            <span class="btn-name-text">{{ $t('coaching.PC_FILTER_PLATFORM') }} :</span>
            <span class="btn-text">{{ platformFilterText }}</span>
          </div>
          <div v-if="_searchText" class="filter-btn search" @click="titleFilterBtnHandler">
            <span class="btn-name-text">{{ $t('coaching.MC_SEARCH') }} :</span>
            <span class="btn-text">{{ _searchText }}</span>
            <img src="/img/icon/close.svg">
          </div>
        </div>
        <div class="tv-content-wrap">
          <div class="tv-content-wrap-inner">
            <h2 class="title">{{ $t('coaching.MS_COUNT_RESULT') + ` (${totalCount})` }}</h2>
            <div v-if="tvContentList.length" class="tv-content-list">
              <CoachTvPlaylistCard v-for="(item, i) in tvContentList" :key="i" :dateTime="item.contentCreatedDatetime" :thumbnailUrl="item.thumbnailUrl" :title="item.title" @click="tvContentCardClickHandler(item)" />
            </div>
            <div v-else class="no-result">
              <img src="/img/icon/icon-frequency.svg">
              <p>{{ $t('coaching.MC_NOT_FOUND_RESULT') }}</p>
            </div>
          </div>
          <div v-if="hasNext" class="more">
            <div class="divider">
              <button @click="moreBtnHandler">
                <span>{{ $t('coaching.BA_qlist_more') }}</span>
                <img src="/img/icon/icon-arrow-down-dark.svg">
              </button>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="right-side">
        <DiscordButton />
        <CoachList />
      </div>
    </div>
  </div>
</template>

<script>
import '@/utils/polyfillUtils';
import _cloneDeep from 'lodash/cloneDeep';
import TextInput from '@shared/components/common/input/TextInput.vue';
import cursorFetcher from '@shared/mixins/cursorFetcher';
import CoachList from '@/views/components/coaching/coachList/CoachList.vue';
import DiscordButton from '@/views/components/coaching/DiscordButton.vue';
import CoachTvFilterModal from '@/views/components/coaching/coachTv/CoachTvFilterModal.vue';
import CoachTvPlaylistCard from '@/views/components/coaching/coachTv/CoachTvPlaylistCard.vue';

export default {
  name: 'CoachTvViewFilter',
  components: {
    DiscordButton, CoachList, TextInput, CoachTvPlaylistCard,
  },
  mixins: [cursorFetcher({
    tvContents: ({ cursor, services, route }) => {
      const playlist = route?.query?.playlist || '';
      const platform = route?.query?.platform || '';
      const title = route?.query?.title || '';
      const params = { cursor, size: 12, order: 'contentCreatedDatetime desc' };
      if (playlist) params.playlist = playlist;
      if (platform) params.platform = platform;
      if (title) params.title = title;
      return services.coaching.getCoachTvContentList(params);
    },
  })],
  watch: {
    $route() {
      this.getFilteredTvContents(true);
    },
  },
  data: () => ({
    keyword: '',
    searchText: '',
    tvContents: {},
    selectedPlaylistFilterList: [],
    selectedPlatformFilterList: [],
  }),
  metaInfo() {
    return {
      title: this.$t('meta.coachTv.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.coachTv.title') },
        { vmid: 'description', content: this.$t('meta.coachTv.description') },
        { vmid: 'url', content: this.$t('meta.coachTv.url') },
        { vmid: 'keywords', content: this.$t('meta.coachTv.keywords') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  computed: {
    playlistFilterText() {
      return this.selectedPlaylistFilterList?.length ? this.selectedPlaylistFilterList.join(', ') : this.$t('coaching.PC_FILTER_SELECTED_ALL');
    },
    platformFilterText() {
      return this.selectedPlatformFilterList?.length ? this.selectedPlatformFilterList.join(', ') : this.$t('coaching.PC_FILTER_SELECTED_ALL');
    },
    tvContentList() {
      return this?.tvContents?.items || [];
    },
    hasNext() {
      return this?.tvContents?.hasNext || false;
    },
    _searchText() {
      return this?.$route?.query?.title;
    },
    cursor() {
      return this?.tvContents?.nextCursor;
    },
    totalCount() {
      return this?.tvContents?.totalCount;
    },
  },
  methods: {
    prevBtnHandler() {
      this.$router.push({ name: 'CoachHome' });
    },
    encodeSpaceUrl(str) {
      if (str) return str.replaceAll(' ', '%20');
      return '';
    },
    async showFilterModal() {
      const result = await this.$modal(CoachTvFilterModal);
      this.selectedPlaylistFilterList = result?.playlist;
      this.selectedPlatformFilterList = result?.platform;

      const playlistQuery = this.encodeSpaceUrl(this.selectedPlaylistFilterList.join(','));
      const platformQuery = this.encodeSpaceUrl(this.selectedPlatformFilterList.join(','));
      const title = this.$route?.query?.title;
      const query = {};

      if (playlistQuery) query.playlist = playlistQuery;
      if (platformQuery) query.platform = platformQuery;
      if (title) query.title = title;

      this.$router.push({ name: 'CoachTvViewFilter', query });
    },
    titleFilterBtnHandler() {
      const query = {};
      const playlist = this.$route?.query?.playlist;
      const platform = this.$route?.query?.platform;

      if (playlist) query.playlist = playlist;
      if (platform) query.platform = platform;

      this.searchText = '';
      this.$router.push({ name: 'CoachTvViewFilter', query });
    },
    setTitleFilter() {
      const query = _cloneDeep(this?.$route?.query) || {};
      if (this.searchText) query.title = this.searchText;
      this.$router.push({ name: 'CoachTvViewFilter', query });
    },
    async getFilteredTvContents(refresh = false) {
      const query = _cloneDeep(this?.$route?.query) || {};
      const cursor = refresh ? 0 : this.cursor;
      const params = { cursor, size: 12, order: 'contentCreatedDatetime desc' };
      if (query.playlist) params.playlist = query.playlist;
      if (query.platform) params.platform = query.platform;
      if (query.title) params.title = query.title;
      const result = await this.$services.coaching.getCoachTvContentList(params);

      if (refresh) this.tvContents = result;
      else {
        const tempContentList = this.tvContentList;
        result.items = [...tempContentList, ...result.items];
        this.tvContents = result;
      }
    },
    moreBtnHandler() {
      this.getFilteredTvContents(false);
    },
    tvContentCardClickHandler(item) {
      const tvContentId = item?.tvContentId;
      const tvContentGroupId = item?.tvContentGroup?.tvContentGroupId;
      this.$router.push({
        name: 'CoachTvViewer',
        params: {
          tvContentId,
          tvContentGroupId,
        },
      });
    },
  },
  mounted() {
    const playlistQuery = this.$route.query?.playlist || '';
    const platformQuery = this.$route.query?.platform || '';
    const titleQuery = this.$route.query?.title || '';
    if (playlistQuery) this.selectedPlaylistFilterList = playlistQuery.replaceAll('%20', ' ').split(',');
    if (platformQuery) this.selectedPlatformFilterList = platformQuery.replaceAll('%20', ' ').split(',');
    if (titleQuery) this.searchText = titleQuery.replaceAll('%20', ' ');
  },
  async asyncData({ services, route, mixinFetcher }) {
    return mixinFetcher.fetch({ services, route });
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-tv-view-filter] {.bgc(#fff); .pb(40);
  .top-wrap { .h(156); .max-w(1260); .m(0, auto); .pt(80); .pr(20); .pl(20);
    .btn-prev { .flex; .w(40); .h(40); transform: rotate(90deg); .bgc(#ebebf0); .br(12);
      &:hover {
        > img { .o(0.8);}
      }
      > img { .m(auto); .o(0.4);}
    }
  }

  .content-wrap { .flex; .m(0, auto); .pr(20); .pl(20);
    .content-holder {.w(100%);
      .search-field { .flex; .mb(40);
        .search-text {.w(100%);}
        .search-btn {.w(48); .h(48); .bgc(#ebebf0); .br(12); .ml(8);}
      }
      .filter-field { .flex; .max-w(100%); flex-wrap: wrap; row-gap: 12px; overflow: hidden;
        .filter-btn { .flex; .items-center; .h(48); .p(0, 32); .mr(12); .bgc(#faf2ff); .br(4); .pointer;
          &.search {.bgc(#fff); border: solid 1px #ebebf0;
            .btn-name-text {color: rgba(26, 26, 26, 0.5);}
            .btn-text {.c(#191919);}
            > img {.w(18); .h(18); .o(0.4); .ml(6);}
          }
          .btn-name-text {.c(rgba(132, 0, 255, 0.5)); .mr(8); white-space: nowrap;}
          .btn-text {.c(#8300ff); .bold; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}
        }
      }
      .tv-content-wrap { .mt(28);
        .tv-content-wrap-inner {
          .title {.mb(28); .fs(28); .bold; .c(#000);}
          .tv-content-list { .flex; flex-wrap: wrap; column-gap: 16px;
            [coach-tv-playlist-card] {.w(100%); .mb(40);}
          }
          .no-result {.flex; flex-direction: column; .justify-center; .items-center; .w(100%); .h(240); .bgc(#ebebf0); border: 1px solid #ebebf0; .br(20);
            > img {.w(36); .h(36); .mb(12);}
            > p {.c(#191919); .fs(14); .bold;}
          }
        }
        .more { .mt(40);
          .divider { .rel;
            > button { .flex; .items-center; .h(40); .abs; .p(0, 8); .bgc(#ebebf0); .c(#000); .br(12); border: none; .z(1); right: 50%; transform: translate(50%, -50%);
              > img {.o(0.4)}
            }
            > hr { .w(100%); .h(2); .bgc(#ebebf0);}
          }
        }
      }
    }
    .right-side {.ml(auto); .hide;}
  }

  @media (@tp-up) {
    .top-wrap { .pr(58); .pl(58); }
    .content-wrap {.pr(58); .pl(58);
      .content-holder > .tv-content-wrap > .tv-content-wrap-inner > .tv-content-list > [coach-tv-playlist-card] {.w(calc(1 / 3 * 100% - 11px))}

    }
  }

  @media (@ds-up) {
    .top-wrap {.pr(0); .pl(0);}
    .content-wrap { .max-w(1260); .pr(0); .pl(0);
      .content-holder { .max-w(848); .w(calc(100% - 432px)); .mr(56); }
      .right-side {display: unset;}
    }
  }

}
</style>
