<template>
  <div coach-tv-filter-modal-view>
    <div class="top-wrap">
      <h1 class="modal-title">{{ $t('coaching.PT_FILTER') }}</h1>
    </div>
    <div class="content-wrap">
      <div class="filter-wrap platform">
        <h2>{{ $t('coaching.PC_FILTER_PLATFORM') }}</h2>
        <div class="filter-item-list">
          <div :class="{active: !hasPlatformFilter}" class="filter-item" @click="selectedPlatformFilterList= []">
            <span>{{ $t('coaching.PC_FILTER_SELECTED_ALL') }}</span>
          </div>
          <div v-for="item in platformFilterList" :key="item" :class="{active: selectedPlatformFilterList.includes(item)}" class="filter-item platform" @click="selectItem(selectedPlatformFilterList, item)">
            <img v-if="item === 'YOUTUBE'" src="/img/icon/icon-youtube-black.svg">
            <img v-if="item === 'TWITCH'" src="/img/icon/icon-twitch-black.svg">
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="filter-wrap playlist">
        <h2>{{ $t('coaching.PC_FILTER_PLAYLIST') }}</h2>
        <div class="filter-item-list">
          <div :class="{active: !hasPlaylistFilter}" class="filter-item" @click="selectedPlaylistFilterList= []">
            <span>{{ $t('coaching.PC_FILTER_SELECTED_ALL') }}</span>
          </div>
          <div v-for="item in playlistFilterList" :key="item" :class="{active: selectedPlaylistFilterList.includes(item)}" class="filter-item playlist" @click="selectItem(selectedPlaylistFilterList, item)">
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-wrap">
      <button class="save-btn" color="black" @click="saveBtnHandler">
        <span>{{ $t('coaching.BT_SAVE') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import '@/utils/polyfillUtils';

export default {
  name: 'CoachTvFilterModalView',
  props: {
    playlistFilterList: {
      type: Array,
      default: () => ([]),
    },
    platformFilterList: {
      type: Array,
      default: () => ([]),
    },
  },
  components: {},
  data: () => ({
    selectedPlaylistFilterList: [],
    selectedPlatformFilterList: [],
  }),
  computed: {
    hasPlatformFilter() {
      return !!this.selectedPlatformFilterList.length;
    },
    hasPlaylistFilter() {
      return !!this.selectedPlaylistFilterList.length;
    },
  },
  methods: {
    selectItem(filterList, item) {
      const idx = filterList.indexOf(item);
      if (idx > -1) filterList.splice(idx, 1);
      else filterList.push(item);
    },
    saveBtnHandler() {
      this.$emit('save', { playlist: this.selectedPlaylistFilterList, platform: this.selectedPlatformFilterList });
    },
  },
  mounted() {
    const playlistQuery = this.$route?.query?.playlist || '';
    const platformQuery = this.$route?.query?.platform || '';
    if (playlistQuery) this.selectedPlaylistFilterList = playlistQuery.replaceAll('%20', ' ').split(',');
    if (platformQuery) this.selectedPlatformFilterList = platformQuery.replaceAll('%20', ' ').split(',');
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-tv-filter-modal-view] { .pb(40);
  .top-wrap { .pb(25); border-bottom: solid 1px #dce0e3;
    .modal-title {.fs(20); }
  }
  .content-wrap { .mt(20);
    .filter-wrap {.mt(20);
      > h2 {.fs(16); .c(#191919);}
      .filter-item-list { .flex; flex-wrap: wrap; justify-content: space-between; gap: 12px; .mt(20);
        .filter-item {.flex; .items-center; .justify-center; .h(48); .w(calc(1 / 3 * 100% - 8px)); .br(4); border: solid 1px #ebebf0; .c(#000); .pointer; overflow: hidden;text-align: center;
          > img {.mr(2); .w(22); .h(22);}
          &.active {.bgc(#8300ff); border: solid 1px rgba(0, 0, 0, 0); .c(#fff); .bold;}
        }
      }
    }
  }
  .bottom-wrap { .mt(80); text-align: center;
    .save-btn { .bgc(#000); .w(100%); .max-w(360); .h(60); .br(30); .c(#fff);
      &:hover {.o(0.5);}
    }
  }
}

</style>
